module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Remarketing Plus","short_name":"Remarketing Plus","start_url":"/","background_color":"#FFFFFF","theme_color":"#3585E4","display":"minimal-ui","icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"67128d03930d8c4ff6f264171a5b9330"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
